import axios from "axios";
import Session from './../Session1'
import { Store } from '../store'

var headres_test = { 'Content-Type': 'application/x-www-form-urlencoded' };
// let myHeader = {
//     'Content-Type': 'application/x-www-form-urlencoded'
// };
if (Session.getItem("deviceToken") && Session.getItem("token")) {
    Session.setItem("myHeader", JSON.stringify({ 'Content-Type': 'application/x-www-form-urlencoded', deviceToken: Session.getItem("deviceToken"), token: Session.getItem("token") }))
}
else if (Session.getItem("deviceToken")) {
    Session.setItem("myHeader", JSON.stringify({ 'Content-Type': 'application/x-www-form-urlencoded', deviceToken: Session.getItem("deviceToken") }))
}
else if (Session.getItem("token")) {
    Session.setItem("myHeader", JSON.stringify({ 'Content-Type': 'application/x-www-form-urlencoded', token: Session.getItem("token") }))
}
else {
    Session.setItem("myHeader", JSON.stringify({ 'Content-Type': 'application/x-www-form-urlencoded' }))

}
let api_url;
let domain_url;
let crm_url;
console.log("hostname", window.location.host)
if (window.location.host === "localhost:3000" || window.location.host === "localhost:3001") {

    api_url = 'http://localhost:81/pro/vikas/elearn-api/';
    domain_url = 'http://localhost:3000/';
    // crm_url = 'http://localhost:81/pro/vikas/elearn-crm';
    crm_url = 'https://cdn.omitec.org/';

}
else if (window.location.host == "dev.omitec.co.in") {
    api_url = 'https://devapi.omitec.co.in/';
    domain_url = 'https://dev.omitec.co.in/';
    crm_url = 'https://devcrm.omitec.co.in/';
}
else if (window.location.host == "omitec.co.in") {
    api_url = 'https://api.omitec.co.in/';
    domain_url = 'https://omitec.co.in/';
    crm_url = 'https://crm.omitec.co.in/';
}
else if (window.location.host == "elearn.omitec.org") {
    api_url = 'https://api.omitec.co.in/';
    domain_url = 'https://elearn.omitec.org/';
    crm_url = 'https://crm.omitec.co.in/';
}
else if (window.location.host == "online.omitec.org") {
    api_url = 'https://api.omitec.co.in/';
    domain_url = 'https://online.omitec.org/';
    crm_url = 'https://cdn.omitec.org/';
}
else {
    api_url = 'https://api.omitec.co.in/';
    domain_url = 'https://online.omitec.org/';
    crm_url = 'https://cdn.omitec.org/';
}

export const DOMAIN_URL = domain_url;
export const CRM_URL = crm_url;
export const post_api = async (purl, data) => {
    // const store_data = Store.getState();
    console.log("post_api");
    console.log(purl, data);
    const headres_test = { 'Content-Type': 'application/x-www-form-urlencoded', 'Access-Control-Allow-Origin': "*" };
    if (Session.getItem("deviceToken") !== false) {
        headres_test["deviceToken"] = Session.getItem("deviceToken");
    }
    if (Session.getItem("token") !== false) {
        headres_test["token"] = Session.getItem("token");
    }
    console.log("headres_test", headres_test);
    return await axios.create({
        baseURL: api_url,
        timeout: 20000,
        headers: headres_test,
        async: true,
        mode: 'cors'
    }).post(purl, data);

}

export default axios.create({
    baseURL: api_url,
    // timeout: 20000,
    headers: headres_test,
    async: true,
    mode: 'cors'
});
