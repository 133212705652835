import './App.css';
import './Main.js';
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap/dist/css/bootstrap.min.css'
import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.min.js'
import "./css/styles.css"
// import AddToHomescreen from 'react-add-to-homescreen';
// import { getToken, onMessageListener } from "./Component/firebaseInit";
// const Navbar = lazy(() => import('./Component/Navbar'));



// import Login from './Component/LoginUser';
// import Location from './Component/location';
// import Dashboard from './Component/Dashboard';
// import ChangePassword from './Component/ChangePassword';
// import Profile from './Component/Profile';
// import myCourse from './Component/myCourse2';
// import Study from './Component/Study';
// import Transactions from './Component/Transactions';
// import Feedback from './Component/Feedback';
// import Queryview from './Component/Queryview';

// import Package from './Component/Package';
// import Package1 from './Component/Package1';
// import Help from './Component/Help';
// import Exam from './Component/Exam';
// import Result from './Component/Result';
// import ExamPaper from './Component/ExamPaper';
// import Notification from './Component/Notification';
// import Wallet from './Component/Wallet';
// import Registration from './Component/Registration';
// import Payment_Registration from './Component/Payment_Registration';
// import Payment_Affliates from './Component/Payment_Affliates';
// import Thankyou from './Component/thankyou';

// import FogetPassword from './Component/FogetPassword';
// import CourseDemo from './Component/CourseDemo';
// import CourseSyllabus from './Component/CourseSyllabus';
// import Certificate from './Component/Certificate';
// import CoinAdd from './Component/CoinAdd';
// import CoinTransfer from './Component/CoinTransfer';
// import Chat from './Component/chat';
// import OtherLink from './Component/Other_Links';
import { setDeviceToken } from './Actions/CourseActions';
import env from "react-dotenv";

import React, { useState, Suspense, lazy } from 'react';
import { Switch, Route, Link } from "react-router-dom";
// import { geolocated } from "react-geolocated";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Session from './Session1';

import Navbar from './Component/navbar';

// const Navbar = lazy(() => import('./Component/navbar'));
// const Courses = lazy(() => import('./Component/Courses'));
const CourseDetail = lazy(() => import('./Component/CourseDetail'));
const Software = lazy(() => import('./Component/Software'));
const Footer = lazy(() => import('./Component/Footer'));
// const BuyCourse = lazy(() => import('./Component/BuyCourse'));
// const Login = lazy(() => import('./Component/LoginUser'));
const Dashboard = lazy(() => import('./Component/Dashboard'));
const ChangePassword = lazy(() => import('./Component/ChangePassword'));
const Profile = lazy(() => import('./Component/Profile'));
// const myCourse = lazy(() => import('./Component/myCourse2'));
const Study = lazy(() => import('./Component/Study'));
const Transactions = lazy(() => import('./Component/Transactions'));
const Feedback = lazy(() => import('./Component/Feedback'));

const Queryview = lazy(() => import('./Component/Queryview'));
const Package = lazy(() => import('./Component/Package'));
const Package1 = lazy(() => import('./Component/Package1'));
const Help = lazy(() => import('./Component/Help'));
const Exam = lazy(() => import('./Component/Exam'));
const Result = lazy(() => import('./Component/Result'));
const ExamPaper = lazy(() => import('./Component/ExamPaper'));
const Notification = lazy(() => import('./Component/Notification'));
const Wallet = lazy(() => import('./Component/Wallet'));
const Registration = lazy(() => import('./Component/Registration'));
const Payment_Registration = lazy(() => import('./Component/Payment_Registration'));
const Payment_Affliates = lazy(() => import('./Component/Payment_Affliates'));
const Thankyou = lazy(() => import('./Component/thankyou'));

const FogetPassword = lazy(() => import('./Component/FogetPassword'));
const CourseDemo = lazy(() => import('./Component/CourseDemo'));
const CourseSyllabus = lazy(() => import('./Component/CourseSyllabus'));
const Certificate = lazy(() => import('./Component/Certificate'));
const CoinAdd = lazy(() => import('./Component/CoinAdd'));
const CoinTransfer = lazy(() => import('./Component/CoinTransfer'));
const Chat = lazy(() => import('./Component/chat'));
const OtherLink = lazy(() => import('./Component/Other_Links'));
const CCPTraining = lazy(() => import('./Component/CCPTraining'));
const CCPView = lazy(() => import('./Component/CCPView'));


console.log("process.env.REACT_APP_BASE_URL", env);

// Session.setItem("mobile-app", true);
if (Session.getItem("deviceToken") === false) {
  // getToken(setDeviceToken);
  // setDeviceToken();
  // getToken(setDeviceToken);
  setDeviceToken();
}

const Loading = () => {
  alert("loafing");
  return <div>Loading route...</div>
};


function App() {

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });


  // onMessageListener().then(payload => {
  //   setShow(true);
  //   // console.log(payload);
  //   // let notification = new Notification("Hi there!","sdcdsc");
  //   setNotification({ title: payload.notification.title, body: payload.notification.body })
  // }).catch(err => console.log('failed: ', err));


  // JSON.stringify(window)
  // if (user.token === false) {
  //   history.push("/Login")
  // }
  document.addEventListener('keyup', (e) => {
    if (e.key === 'PrintScreen') {
      navigator.clipboard.writeText('');
      // alert('Screenshots disabled!');
    }
  });


  return (
    <div className="App" >

      <Suspense fallback={Loading}>
        <Navbar />

        <ToastContainer />
        {/* {
          (Session.getItem("mobile-app") == "true") ? <></> :
            <AddToHomescreen title='Hii' onAddToHomescreenClick={() => { }} />
        } */}
        {/* <Location /> */}
        <Switch>
          {/* <Route path="/" exact component={Home} /> */}
          <Route path="/" exact component={lazy(() => import('./Component/Home'))} />
          <Route path="/BuyCourse/:id/:pack" exact component={lazy(() => import('./Component/BuyCourse'))} />
          <Route path="/myCourse" exact component={lazy(() => import('./Component/myCourse2'))} />
          <Route path="/Course/" exact component={lazy(() => import('./Component/Courses'))} />
          <Route path="/Course/:course_id/:type/:tab?" exact component={lazy(() => import('./Component/CourseDetail'))} />

          <Route path="/Study" exact component={Study} />
          {/* <Route path="/Quiz/:mid/:set" component={(props) => (<QuizDiv courseName={coursedata.courseName} {...props} />)} />
        <Route path="/Test/:mid/:set" component={(props) => (<Test courseName={coursedata.courseName} {...props} />)} />
        <Route path="/Result" component={(props) => (<Result {...props} />)} /> */}
          <Route path="/Login/:user?/:pass?/:url?" exact component={lazy(() => import('./Component/LoginUser'))} />
          <Route path="/Dashboard" exact component={lazy(() => import('./Component/Dashboard'))} />
          <Route path="/Profile/:type?" exact component={Profile} />
          <Route path="/ChangePassword" exact component={ChangePassword} />
          <Route path="/Transactions" exact component={Transactions} />
          <Route path="/Feedback" exact component={Feedback} />
          <Route path="/Queryview" exact component={Queryview} />
          <Route path="/Package/:course_id" exact component={Package} />
          <Route path="/Package1/:course_id" exact component={Package1} />
          <Route path="/Help" exact component={Help} />
          <Route path="/Exam" exact component={Exam} />
          <Route path="/ExamPaper/:examId" exact component={ExamPaper} />
          <Route path="/Result" exact component={Result} />
          <Route path="/Notification" exact component={Notification} />
          <Route path="/Wallet" exact component={Wallet} />
          <Route path="/Registration/:refId?" exact component={Registration} />
          <Route path="/FogetPassword" exact component={FogetPassword} />
          <Route path="/CourseDemo/:course_id" exact component={CourseDemo} />
          <Route path="/CourseSyllabus/:file" exact component={CourseSyllabus} />
          <Route path="/Certificate/:id" exact component={Certificate} />
          <Route path="/CoinAdd" exact component={CoinAdd} />
          <Route path="/CoinTransfer" exact component={CoinTransfer} />
          <Route path="/Software" exact component={Software} />
          <Route path="/chat/:receiver_id" exact component={Chat} />
          <Route path="/payment/:link" exact component={(props) => <Payment_Registration name="Payment page" {...props} />} />
          <Route path="/thankyou" exact component={Thankyou} />
          <Route path="/Payment_Affliates" exact component={Payment_Affliates} />
          <Route path="/Other_Links" exact component={OtherLink} />
          <Route path="/CCPTraining" exact component={CCPTraining} />
          <Route path="/view-cpp" exact component={CCPView} />

        </Switch>
        <Footer />
        <Link to="/Help" id="helpbtn">
          <button className="rounded-pill btn btn-primary shadow"
            style={{ position: "fixed", left: "10px", bottom: "10px", height: "60px", width: "60px", lineHeight: 1, zIndex: "10000 !important", backgroundColor: "#6b0f1a", backgroundImage: "linear-gradient(315deg, #6b0f1a 0%, #b91372 74%)", border: "none" }}>Help Desk</button>
        </Link>
      </Suspense>
      {/* <a href="https://play.google.com/store/apps/details?id=co.khal.oiimi">
        <button className="btn btn-primary shadow"
          style={{ position: "fixed", left: "0", bottom: "0px", height: "60px", width: "100%", lineHeight: 1, zIndex: "10000 !important", backgroundColor: "#6b0f1a", backgroundImage: "linear-gradient(315deg, #009ae0 0%, #009ae0 74%)", border: "none", fontSize: "20px" }}>
          <img src="https://oiimi.courses.store/33997f54afd933e518d2d2199f97a66c.svg" height="80%" className="me-4"></img>
          <i className="fa fa-download"></i> &nbsp;
          Install App
        </button>
      </a> */}
    </div >
  );
}

export default App;
