
import { history } from '../Apphistory'
import Session from '../Session1'

export const FixHeader = (data = "no top-nav-collapse") => (dispatch) => {
    dispatch({ type: "FixHeader", class: data });
};
export const profile_filled = (data = false) => (dispatch) => {
    dispatch({ type: "profile_filled", data: data });
};

export const hideContent = (data = false) => (dispatch) => {
    dispatch({ type: "hideContent", data: data });
};


export const checkAccess = () => {
    if (window.location.pathname != "/") {
        if (!Session.getItem("token")) {
            history.push("/")
        }
    }
}

export const getTime = (date) => {
    date = new Date(date);
    let hour = date.getHours();
    if (hour > 12) {
        hour = hour - 12;
    }
    let ampm = hour >= 12 ? 'pm' : 'am';
    return hour + ":" + date.getMinutes() + " " + ampm;
}